<template>
  <section id="charts" class="landing-charts-container" tabindex="0">
    <div class="charts-large-sm-view inner-container">
      <el-row :gutter="10" type="flex" class="charts-row">
        <el-col v-for="(chart, index) in charts" :key="index" class="charts-col">
          <div>
            <component 
              :is="chart.component"
              v-if="chart.condition"
              :res-chart-data="chart.data"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="charts-mid-view inner-container">
      <el-row type="flex" justify="center">
        <el-col :span="12" class="charts-col">
          <div>
            <ChartPlace
              v-if="allPlaceTypeNum"
              :res-chart-data="{allPlaceTypeNum}"
            />
          </div>
        </el-col>
        <el-col :span="12" class="charts-col">
          <div>
            <ChartSport
              v-if="sportMainTypeNum"
              :res-chart-data="{sportMainTypeNum}"
            />
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="12" class="charts-col">
          <div>
            <ChartGender
              v-if="genderNum"
              :res-chart-data="{genderNum}"
            />
          </div>
        </el-col>
        <el-col :span="12" class="charts-col">
          <div>
            <ChartCity
              v-if="cityNum.length"
              :res-chart-data="{cityNum}"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="last-update"> {{ $t('general.LastUpdateDate') }}：{{ lastUpdateDate }}</div>
  </section>
</template>

<script>
import ChartGender from './ChartGender.vue'
import ChartSport from './ChartSport.vue'
import ChartPlace from './ChartPlace.vue'
import ChartCity from './ChartCity.vue'

export default {
  name: 'DataChart',
  components: {
    ChartPlace,
    ChartSport,
    ChartGender,
    ChartCity
  },
  props: {
    cityNum: {
      type: Array,
      default: () => []
    },
    sportMainTypeNum: {
      type: Object,
      default: () => ({})
    },
    genderNum: {
      type: Object,
      default: () => ({})
    },
    allPlaceTypeNum: {
      type: Object,
      default: () => ({})
    },
    lastUpdateDate: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      charts: [
        {
          component: 'ChartPlace',
          condition: this.allPlaceTypeNum !== null,
          data: {
            allPlaceTypeNum: this.allPlaceTypeNum
          }
        },
        {
          component: 'ChartSport',
          condition: this.sportMainTypeNum !== null,
          data: {
            sportMainTypeNum: this.sportMainTypeNum
          }
        },
        {
          component: 'ChartGender',
          condition: this.genderNum !== null,
          data: {
            genderNum: this.genderNum
          }
        },
        {
          component: 'ChartCity',
          condition: this.cityNum.length > 0,
          data: {
            cityNum: this.cityNum
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.landing-charts-container{
  padding-bottom: 1.25rem;
  .charts-large-sm-view{
    @media (min-width: 1301px){
      .charts-row{
        justify-content: space-around;
      }
    }
    @media  (max-width: 1300px) and (min-width: 769px){
      display: none;
    }
    @media (max-width: 768px){
      padding: 0rem;
      .charts-row{
        flex-wrap: wrap;
        justify-content: center;
      }
      .charts-col{
        flex-basis: 100%;
        max-width: 300px;
        margin-bottom: 10px;
      }
    }
  }
  @media (min-width: 1301px){
    
    .charts-sm-view{
      display: none;
    }
    .charts-mid-view{
      display: none;
    }
  }
  @media  (max-width: 1300px) and (min-width: 769px){
    .charts-sm-view{
      display: none;
    }
    .charts-mid-view{
      display: block;
    }
  }
  @media (max-width: 768px){
    .charts-mid-view{
      display: none;
    }
    .charts-sm-view{
      display: block;
    }
  }
  .last-update{
    padding-right: 2rem;
    text-align: right;
    font-size: 0.875rem;
  }
  .inner-container{
    max-width: 90vw;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  @media (max-width: 1200px){
    .inner-container{
      max-width: 100%;
    }
  }
  .charts-mid-view{
    .charts-col{
      max-width: 400px;
      margin-bottom: 10px;
    }
  }
  .charts-sm-view{
    padding: 0rem;
    .charts-col{
      flex-basis: 100%;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}
</style>
