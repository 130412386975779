<template>
  <div class="landing-promote-container">
    <div class="image-block">
      <router-link
        :to="isLogin ? { name: 'SdcampLogin' } : { name: 'Sdcamp' }"
      >
        <img :src="image" class="image rwd-desk-view" aria-hidden="true">
        <img :src="imagePhone" class="image rwd-phone-view" aria-hidden="true">
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LandingSpecial',
  data () {
    return {
      image: require('@/assets/images/landingPromote/promote-desk.svg'),
      imagePhone: require('@/assets/images/landingPromote/promote-phone.svg')
    }
  },
  computed: {
    ...mapGetters(['token']),
    isLogin () {
      return !!this.token
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.landing-promote-container {
  position: relative;
}

.image-block {
  position: relative;

  .image {
    width: 100%;
  }
}

.rwd-desk-view{
    @media (min-width: 577px){
      display: block;
    }
    @media (max-width: 576px){
      display: none;
    }
  }
  .rwd-phone-view{
    @media (min-width: 577px){
      display: none;
    }
    @media (max-width: 576px){
      display: block;
    }
  }
</style>
