<template>
  <section id="dataset" class="landing-dataset-container" tabindex="0">
    <div class="inner-container">
      <div class="section-h2 section-title">
        {{ $t('Dataset.DatasetTitle') }}
      </div>
      <el-row :gutter="20" type="flex" justify="center" class="dataset-row">
        <el-col v-for="(item, index) in dataset" :key="index" class="dataset-col">
          <el-card :body-style="{ padding: '0px' }" shadow="hover" tabindex="0">
            <div class="card-image-container">
              <img :src="item.img" class="image" aria-hidden="true" alt="">
            </div>
            <div :class="['card-body',langStyle]">
              <template v-if="language === 'zh-TW'">
                <div>
                  <div class="section-h3">
                    {{ item.zh.title }}
                  </div>
                  <div class="description-section">
                    {{ item.zh.description }}
                  </div>
                </div>
                <div>
                  <div class="sr-only">
                    {{ $t('Dataset.Column') }}
                  </div>
                  <div class="description-section">
                    {{ item.zh.measureitem }}
                  </div>
                </div> 
              </template>
              <template v-else-if="language === 'jp'">
                <div>
                  <div class="section-h3">
                    {{ item.jp.title }}
                  </div>
                  <div class="description-section">
                    {{ item.jp.description }}
                  </div>
                </div>
                <div>
                  <div class="sr-only">
                    {{ $t('Dataset.Column') }}
                  </div>
                  <div class="description-section">
                    {{ item.jp.measureitem }}
                  </div>
                </div> 
              </template>
              <template v-else>
                <div>
                  <div class="section-h3">
                    {{ item.en.title }}
                  </div>
                  <div class="description-section">
                    {{ item.en.description }}
                  </div>
                </div>
                <div>
                  <div class="sr-only">
                    {{ $t('Dataset.Column') }}
                  </div>
                  <div class="description-section">
                    {{ item.en.measureitem }}
                  </div>
                </div> 
              </template>
              <div class="updated-at sub-description-section">
                {{ UTCtoLocalTime(item.created_at) }}
                {{ $t('general.Update') }}
              </div>
              <el-row type="flex" class="dataset-info-icon sub-description-section">
                <el-col :span="6">
                  <i class="el-icon-view" :aria-label="$t(`Dataset.ViewCount`)" /> {{ item.views_count }}
                </el-col>
                <el-col>
                  <i class="el-icon-download" :aria-label="$t(`Dataset.DownloadCount`)" /> {{ item.downloads_count }}
                </el-col>
              </el-row>
            </div>
            <template v-if="!isLogin">
              <div class="card-footer">
                <el-button
                  class="btn-dataset-download"
                  type="button"
                  @click="dialogVisible = true"
                >
                  {{ $t(`general.Download`) }}
                </el-button>
              </div>
            </template>
          </el-card>
        </el-col>
      </el-row>
      <el-dialog
        :title="$t(`general.Notify`)"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <span>{{ $t(`Dataset.LoginWithConsumer`) }}</span>
        <span slot="footer" class="dialog-footer">
          <RouterButton 
            :button-content="buttonContent"
            :router-name="buttonRouter"
          />
        </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { UTCtoLocalTime } from '@/utils/handleTime'
import RouterButton from '@/components/Button/RouterButton.vue'
import { getDatasetList } from '@/api/dataset'

export default {
  name: 'LandingDataset',
  components: {
    RouterButton
  },
  data () {
    return {
      buttonContent: this.$t('general.Confirm'),
      buttonRouter: 'Login',
      dataset: [],
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters(['language', 'token']),
    langStyle () {
      return this.language === 'zh-TW' ? '' : 'lang-style-card-body'
    },
    isLogin () {
      return !!this.token
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData () {
      this.getDatasetList()
    },
    UTCtoLocalTime(time, format) {
      return UTCtoLocalTime(time, format)
    },
    async getDatasetList() {
      const para = {
        landing: true
      }
      try {
        const res = (await getDatasetList(para)).data
        this.dataset = res
          .map(item => {
            const newObj = {
              ...item,
              img: `/images/datasets/${item.img}`
            }
            return newObj
          })
      } catch (error) {
        console.error('getDatasetList-error: ', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.landing-dataset-container{
    .section-title{
      color: $text_title;
      padding: 1.25rem 0;
    }
    @media (max-width: 768px){
      .section-title{
        text-align: center;
      }
    }
    .inner-container{
      max-width: 1200px;
      margin: 0 auto;
      padding: 1.25rem 0;
    }
    @media (max-width: 1200px){
      .inner-container{
        max-width: 95%;
      }
    }
    .card-image-container{
      text-align: center;
      height: 127px;
    }
    .image{
      max-width: 100%;
      height: 100%;
      width: auto;
      display: inline-block;
      object-fit: cover;
    }
    .card-body{
      line-height: 1.5;
      padding: 1rem;
      height: 340px;
      @media (max-width: 520px) { 
        height: auto;
      }
      .file-type{
        border-radius: 4px;
        margin-right: 5px;
        padding: 0 5px;
        font-size: 0.875rem;
      }
      .json{
        background-color: $sport_yellow;
      }
      .csv{
        background-color: $physicalFitness_turquoise;
      }
      .xml{
        background-color: $physiology_green;
      }
    }
    .lang-style-card-body{
      height: 500px;
    }
    .card-footer{
      display: flex;
      justify-content: center;    
      padding-bottom: 1.5rem;
      .btn-dataset-download{
        width: 250px;
        min-width: 200px;
        background-color: $background_blue;
        color:$text_light;
      }
    }
    .description-section{
      margin: 0.4rem 0;
      overflow-wrap: break-word;
      @media (max-width: 1200px) and (min-width: 520px) { 
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .sub-description-section{
      font-size: 0.875rem;
    }
    .dataset-row{
      flex-wrap: wrap;
    }
    .dataset-col{
      flex-basis: 100%;
      max-width: 300px;
      @media (max-width: 768px){
        max-width: 250px;
        margin-bottom: 10px;
      }
    }
    .dialog-footer{
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
