<template>
  <section class="landing-joinus-container">
    <div class="joinus-desk-pad-view  inner-container">
      <el-row class="joinus-body">
        <el-col class="main-contain">
          <div class="section-h2 section-title">
            {{ $t('JoinUs.Slogan1') }}
          </div>
          <div class="slogan">
            {{ $t('JoinUs.Slogan2') }},
            <RouterButton 
              :button-content="buttonContent"
              :router-name="buttonRouter"
              class="router-button"
            />
          </div>
        </el-col>
        <img :src="joinus" :class="['image',langStyleImg]" alt="" aria-hidden="true">
      </el-row> 
    </div>
    <div class="joinus-phone-view  inner-container">
      <el-row class="joinus-body">
        <el-col class="main-contain">
          <div class="section-h2 section-title">
            {{ $t('JoinUs.Slogan3') }},
          </div>
          <div class="slogan">
            {{ $t('JoinUs.Slogan4') }},
          </div>
          <RouterButton 
            :button-content="buttonContentPhone"
            :router-name="buttonRouter"
          />
        </el-col>
      </el-row> 
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import RouterButton from '@/components/Button/RouterButton.vue'

export default {
  name: 'LandingJoinUs',
  components: {
    RouterButton
  },
  data () {
    return {
      joinus: require('@/assets/images/join-us-desk-pad.png'),
      buttonRouter: 'Register',
      buttonContent: this.$t(`JoinUs.Welcome`),
      buttonContentPhone: this.$t(`general.Welcome`)
    }
  },
  computed: {
    ...mapGetters(['language']),
    langStyleImg () {
      return this.language === 'zh-TW' ? '' : 'lang-style-img'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.landing-joinus-container{
    background-color: $background_dark;
    color:$text_light;
    .inner-container{
      max-width: 1200px;
      margin: 0 auto;
      padding: 1.25rem 0;
    }
    .section-title{
      color: $text_light;
      padding: 1.25rem 0;
    }
    .router-button{
      display: inline-block
    }
    .joinus-desk-pad-view{
      @include hidden-by-phone;
      .joinus-body{
        padding: 1rem 0 0 8rem;
        position: relative;
        .main-contain{
          height: 300px;
          position: relative;
          .router-button{
            display: inline-block;
            padding: 5px;
          }
        }
        .image {
          position: absolute;
          bottom:15%;
          right: 5%;
          width: 55%;
        }
        .image.lang-style-img{
          bottom:5%;
        }
      }
    }
    .joinus-phone-view{
      @include hidden-by-desk;
      @include hidden-by-pad;
      @media (max-width: 576px){
        .joinus-body{
          background:url(../../../assets/images/join-us-phone-background.png) bottom right no-repeat;
          background-size: 70%;
          padding: 1rem 2rem;
          .main-contain{
            height: 450px;
            position: relative;
            .router-button {
              position: absolute;
              top: 45%;
            }
          }
        }
      }
    } 
  }
</style>
