<template>
  <section id="introduce" class="landing-introduce-container" tabindex="0">
    <div class="introduce-desk-pad-view inner-container">
      <el-card :body-style="{ padding: '0px' }">
        <el-row class="introduce-body">
          <el-col :span="10">
            <div class="section-h2 section-title">
              {{ $t('Introduce.Data_Specification') }}
            </div>
            <div class="description">
              {{ $t('Introduce.Data_Specification_Content') }}
            </div>
            <RouterButton 
              :button-content="$t('general.SeeMore')"
              :router-name="isLogin ? 'ServiceLogin' : 'Service'"
              :dark-mode="true"
            />
          </el-col>
          <el-col :span="14">
            <img :src="dataspec" class="image" :alt="$t('navbar.Spec') + $t('Aria.Page') + $t('Aria.SamplePic')">
          </el-col>
        </el-row> 
      </el-card>
    </div>
    <div class="introduce-phone-view inner-container">
      <el-card :body-style="{ padding: '0px' }">
        <el-row class="introduce-body">
          <div class="section-h2 section-title">
            {{ $t('Introduce.Data_Specification') }}
          </div>
          <div class="description">
            {{ $t('Introduce.Data_Specification_Content') }}
          </div>
          <RouterButton 
            :button-content="$t('general.SeeMore')"
            router-name="Service"
            :dark-mode="true"
            class="router-button-phone"
          />
        </el-row>
      </el-card>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import RouterButton from '@/components/Button/RouterButton.vue'

export default {
  name: 'LandingIntroduce',
  components: {
    RouterButton
  },
  data () {
    return {
      dataspec: require('@/assets/images/dataspec.png')
    }
  },
  computed: {
    ...mapGetters(['token']),
    isLogin () {
      return !!this.token
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.landing-introduce-container{
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .section-title{
    color: $text_title;
    padding: 1.25rem 0;
  }

  @media (max-width: 768px){
    .section-title{
      text-align: center;
    }
  }

  .introduce-desk-pad-view{
    .introduce-body{
      padding: 3rem 3rem 3rem 8rem;
      .description{
        padding-bottom: 3rem;
        line-height: 1.5;
      }
      .image {
        width: 80%;
        margin: 0 auto;
        display: block;
      }
    }
    @include hidden-by-phone;
    @media (max-width: 768px){
      .introduce-body{
        padding: 3rem;
        .image {
          width: 80%;
          margin: 5rem auto;
          display: block;
        }
      }
    }
  }

  .introduce-phone-view{
    @include hidden-by-desk;
    @include hidden-by-pad;
    @media (max-width: 576px){
      .introduce-body{
        padding: 2rem;
      }
      .description{
        padding-bottom: 2rem;
        line-height: 1.5;
      }
      .router-button-phone{
        margin: 0 auto;
      }
      .image {
        width: 80%;
        margin: 1rem auto;
        display: block;
      }
    }
  }
}
</style>
