<template>
  <section id="news" class="landing-news-container">
    <div class="news-desk-view inner-container">
      <div class="section-h2 section-title"> {{ $t('navbar.News') }}</div>
      <!-- <el-row class="news-row">
        <router-link :to="{ name: 'PlatformUpdates' }">
          <div class="newsList_item">
            【公告】運動數據平台「基本資料」與「身體數值」規格更新，8/1正式實施
          </div>
        </router-link>
      </el-row>
      <el-row class="news-row">
        <router-link :to="{ name: 'Updates02FitnessData' }">
          <div class="newsList_item">
            【公告】數據格式優化公告
          </div>
        </router-link>
      </el-row> -->
      <el-row v-for="(item, index) in newsList" :key="index" class="news-row">
        <a 
          :href="item.link"
          :aria-label="$t('Aria.TargetBlank')+','+$t('Aria.Goto')+item.title"
          target="_blank"
        >
          <div class="newsList_item">
            <div>{{ item.title }}</div>
          </div>
        </a>
      </el-row>
    </div>
  </section>
</template>

<script>
import { getNews } from '@/api/news'

export default {
  name: 'LandingNews',
  data () {
    return {
      newsList: []
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.getNews()
    },
    async getNews() {
      try {
        const res = await getNews()
        this.newsList = res.data
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";

.landing-news-container{
  padding-bottom: 2rem;
  .section-title{
    color: $text_title;
    padding: 1.25rem 0;
  }
  .news-row{
    padding: 0 2rem;
  }
  .newsList_item{
    padding: 1.25rem;
    border-bottom: 1px solid $text_dark;
  }
  .newsList_item:hover{
    background-color:$bg_site;
  }

  @media (max-width: 768px){
    .section-title{
      text-align: center;
    }
  }
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  @media (max-width: 1200px){
    .inner-container{
      max-width: 95%;
    }
  }
}

</style>
