<template>
  <div class="landing-banner-container">
    <div>
      <div class="banner-desk-view inner-container">
        <el-row type="flex">
          <el-col
            :sm="{span:10}"
            :md="{span:10}"
            :lg="{span:10}" 
            :xl="{span:10}"
            class="banner-left"
          >
            <div class="banner-total-content">
              <el-row class="banner-total-numbers">
                <animated-number
                  :value="allCount"
                  :format-value="formatToLocaleString"
                  :duration="1000"
                />
              </el-row>
              <el-row class="banner-total-text">
                {{ $t('Banner.Accumulated_Data') }}
              </el-row>
              <el-row class="banner-desc-text">
                {{ $t('Banner.Accumulated_Data_Content') }}
              </el-row>
              
            </div>
          </el-col>
          <el-col
            :sm="{span:9}"
            :md="{span:9}"
            :lg="{span:8}" 
            :xl="{span:8}"
          />
          <el-col
            :sm="{span:5}"
            :md="{span:5}"
            :lg="{span:6}" 
            :xl="{span:6}"
            type="flex"
            class="banner-right"
          >
            <el-row class="banner-datas-block">
              <div class="banner-datatype-text">
                {{ $t('Banner.Sport_Data') }}
              </div>
              <div class="banner-datatype-numbers">
                <animated-number
                  :value="sport"
                  :format-value="formatToLocaleString"
                  :duration="800"
                />
              </div>
            </el-row>
            <el-row class="banner-datas-block">
              <div class="banner-datatype-text">
                {{ $t('Banner.Physical_Fitness_Data') }}
              </div>
              <div class="banner-datatype-numbers">
                <animated-number
                  :value="physicalFitness"
                  :format-value="formatToLocaleString"
                  :duration="800"
                />
              </div>
            </el-row>
            <el-row class="banner-datas-block">
              <div class="banner-datatype-text">
                {{ $t('Banner.Physiology_Data') }}
              </div>
              <div class="banner-datatype-numbers">
                <animated-number
                  :value="physiology"
                  :format-value="formatToLocaleString"
                  :duration="800"
                />
              </div>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div class="banner-pad-phone-view">
        <div class="banner-total-content">
          <el-row class="banner-total-numbers">
            <animated-number
              :value="allCount"
              :format-value="formatToLocaleString"
              :duration="1000"
            />
          </el-row>
          <el-row class="banner-total-text">
            {{ $t('Banner.Accumulated_Data') }}
          </el-row>
          <el-row class="banner-desc-text">
            {{ $t('Banner.Accumulated_Data_Content') }}
          </el-row>
        </div>
      </div>
    </div>
    <section class="banner-bottom-container">
      <el-row
        type="flex"
        justify="space-between"
        class="pad-view"
      >
        <el-col :span="8">
          <div class="banner-datatype-text">
            {{ $t('Banner.Sport_Data') }}
          </div>
          <div class="banner-datatype-numbers">
            <animated-number
              :value="sport"
              :format-value="formatToLocaleString"
              :duration="800"
            />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="banner-datatype-text">
            {{ $t('Banner.Physical_Fitness_Data') }}
          </div>
          <div class="banner-datatype-numbers">
            <animated-number
              :value="physicalFitness"
              :format-value="formatToLocaleString"
              :duration="800"
            />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="banner-datatype-text">
            {{ $t('Banner.Physiology_Data') }}
          </div>
          <div class="banner-datatype-numbers">
            <animated-number
              :value="physiology"
              :format-value="formatToLocaleString"
              :duration="800"
            />
          </div>
        </el-col>
      </el-row>
      <el-row
        class="phone-view"
      >
        <el-col class="phone-view-block">
          <div class="banner-datatype-text">
            {{ $t('Banner.Sport_Data') }}
          </div>
          <div class="banner-datatype-numbers">
            <animated-number
              :value="sport"
              :format-value="formatToLocaleString"
              :duration="800"
            />
          </div>
        </el-col>
        <el-col class="phone-view-block">
          <div class="banner-datatype-text">
            {{ $t('Banner.Physical_Fitness_Data') }}
          </div>
          <div class="banner-datatype-numbers">
            <animated-number
              :value="physicalFitness"
              :format-value="formatToLocaleString"
              :duration="800"
            />
          </div>
        </el-col>
        <el-col class="phone-view-block">
          <div class="banner-datatype-text">
            {{ $t('Banner.Physiology_Data') }}
          </div>
          <div class="banner-datatype-numbers">
            <animated-number
              :value="physiology"
              :format-value="formatToLocaleString"
              :duration="800"
            />
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'
import { getHomeData } from '@/api/dashboard'
import { FormatNumber } from '@/Mixins'

export default {
  name: 'LandingBanner',
  components: { AnimatedNumber },
  mixins: [FormatNumber],
  data () {
    return {
      physiology: 0,
      physicalFitness: 0,
      sport: 0,
      allCount: 0
    }
  },
  mounted() {
    this.getDashboardData()
    this.getInfo()
  },
  methods: {
    async getDashboardData() {
      const res = await getHomeData()
      this.physiology = res.data.Physiology ? res.data.Physiology : 0
      this.physicalFitness = res.data.PhysicalFitness ? res.data.PhysicalFitness : 0
      this.sport = res.data.Sport ? res.data.Sport : 0
      this.allCount = res.data.all_count ? res.data.all_count : 0
    },
    async getInfo () {
      this.$store.dispatch('info/getDatatype')
    },
    login () {
      this.hideLogin = false
    },
    emitCloseLogin() {
      this.hideLogin = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
  .landing-banner-container{
    color:$hover_dark_blue;
    background-size: cover; //contain
    position: relative;
    .inner-container{
      max-width: 1200px;
      margin: 0 auto;
      padding: 1.25rem 0; // 待確認
    }
    @media (min-width: 768px){
      .inner-container{
        max-width: 95%;
      }
    }
    .banner-left{
      position: relative;
      .banner-total-content{
        position: absolute;
      }
      .banner-total-numbers{
        font-weight: bold;
        font-size: 3rem;
        padding-bottom: 1.25rem;
      }
      .banner-total-text{
        font-size: 2rem;
        padding-bottom: 1.25rem;
      }
      .banner-desc-text{
        line-height: 1.5;
        color: $text_dark;
      }
      .router-button{
        position: absolute;
        bottom: 1rem;
        right: 0;
      }
    }
    .banner-right{
      @include hidden-by-pad;
      align-items: flex-end;

      .banner-datas-block{
        display: flex;
        flex-direction:column;
        justify-content: end;
        min-height: 180px;
        
        .banner-datatype-numbers{
          font-size: 3rem;
          font-weight: bold;
        }
        .banner-datatype-text{
          font-size: 2rem;
        }
      }
    }
    .banner-desk-view{
      @include hidden-by-pad;
      @include hidden-by-phone;
      background:url(../../../assets/images/banner_background.png) top center no-repeat;
      background-size: cover; //contain
      .banner-left{
        .banner-total-content{
          top: 30%;
          left: 10%;
        }
        .banner-desc-text{
          width: 420px;
        }
      }
    }

    .banner-pad-phone-view{
      @include hidden-by-desk;
      @media (max-width: 768px) and (min-width: 577px) { 
        background:url(../../../assets/images/banner_background_pad.png) top center no-repeat;
        background-size: cover; //contain
        min-height: 540px;
      }
      .banner-total-content{
        position: absolute;
        bottom: 35%;
        left: 10%;
        .banner-total-numbers{
          font-weight: bold;
          font-size: 3rem;
          padding-bottom: 1.25rem;
        }
        .banner-total-text{
          font-size: 2rem;
          padding-bottom: 1.25rem;
        }
        .banner-desc-text{
          width: 350px;
          line-height: 1.5;
          color: $text_dark;
        }
      }

      @media (max-width: 576px) { 
        background:url(../../../assets/images/banner_background_phone.png) top center no-repeat;
        background-size: cover; //contain
        min-height: 300px;
        .banner-total-content{
          margin: 0 auto;
          padding: 2rem;
          position: initial;
          .banner-total-numbers{
            font-weight: bold;
            font-size: 2rem;
            padding-bottom: 1.25rem;
          }
          .banner-desc-text{
            width: 300px;
          }
        }
      }
    }
  }
  .banner-bottom-container{
    @include hidden-by-desk;
    color: $text_light;
    background-color: $background_dark;
    text-align: center;
    padding: 1.875rem 0;
    .banner-datatype-numbers{
      font-size: 3rem;
      font-weight: bold;
    }
    .banner-datatype-text{
      font-size: 2rem;
    }
    .pad-view{
      @include hidden-by-phone;
    }
    .phone-view{
      @include hidden-by-pad;
      &-block{
        padding: 1.5rem 0;
      }
      
    }
  }

</style>
