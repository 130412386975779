<template>
  <section id="statistic" class="landing-statistic-container"> 
    <div class="statistic-desk-view inner-container">
      <MainContain /> 
      <el-row type="flex" justify="space-around">
        <el-col 
          v-for="(item, index) in statistic"
          :key="`${item.title}-${index}`"
          :span="4"
          class="statistic-col"
        >
          <el-card :body-style="{ padding: '0px' }">
            <div class="statistic-num">
              {{ item.statistic }}
            </div>
            <div>
              {{ $t(`LandingStatistic.${item.key}`) }}
              <div class="statistic-popover-container">
                <el-popover
                  placement="bottom"
                  width="200"
                  trigger="click"
                >
                  <div class="white-space">{{ item.info }}</div>
                  <el-button slot="reference">
                    <i class="el-icon-info" :aria-label="$t(`Aria.ReadInformation`)" />
                  </el-button>
                </el-popover>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="statistic-pad-phone-view inner-container">
      <el-row type="flex" justify="center" class="statistic-row" :gutter="10">
        <el-col 
          v-for="(item, index) in statistic"
          :key="`${item.title}-${index}`"
          class="statistic-col"
        >
          <a :href="item.url" target="_blank">
            <el-card :body-style="{ padding: '0px' }">
              <div class="statistic-num"> {{ item.statistic }}</div>
              <div>{{ $t(`LandingStatistic.${item.key}`) }}</div>
            </el-card>
          </a>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'StatisticNum',
  components: {
    MainContain
  },
  props: {
    statisticNum: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      statistic: [],
      statisticInfo:
      {
        org_count: this.$t(`LandingStatistic.org_count_desc`),
        place_type: this.$t(`LandingStatistic.place_type_desc`),
        device_type: this.$t(`LandingStatistic.device_type_desc`),
        type: this.$t(`LandingStatistic.type_desc`)
      }
    }
  },
  mounted() {
    if (this.statisticNum) {
      this.setStatistic()
    }
  },
  methods: {
    setStatistic() {
      this.statistic = Object.keys(this.statisticNum).map(key => {
        return {
          statistic: this.statisticNum[key],
          key: key,
          info: this.statisticInfo[key]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
 .landing-statistic-container{
  position: relative;
  text-align: center;
  .statistic-num{
    font-size: 2.5rem;
    line-height: 1.5;
  }
  @media (min-width: 1201px){
    .statistic-desk-view{
      display: block;
    }
    .statistic-pad-phone-view{
      display: none;
    }
  }
  @media (max-width: 1200px){
    .statistic-desk-view{
      display: none;
    }
    .statistic-pad-phone-view{
      display: block;
    }
  }
  
  .inner-container{
    margin: 0 auto;
    padding: 1rem 0;
    @media (min-width: 1201px){
      max-width: 1200px;
    }
    @media (max-width: 1200px){
      max-width: fit-content;
    }
  }
  .statistic-desk-view{
    .statistic-col:hover{
      transform: scale(1.1);
      transition: transform 0.2s ease-in-out;
    }
  }
  .statistic-pad-phone-view{
    .statistic-row{
      flex-wrap: wrap;
    }
    .statistic-col{
      flex-basis: 100%;
      max-width: 150px;
      margin-bottom: 10px;
    }
    .el-card{
      max-width: 150px;
    }
  }
  .el-card{
    padding: 2rem 0;
    .statistic-popover-container{
      display: none;
      
        .el-button{
          background-color: transparent;
          border: transparent;
          padding: 5px;
        }
      i{
        color: $sport_yellow;
      }
    }
  }
  .el-card:hover{
    .statistic-popover-container{
      display: inline-block;
    }
  }
}
</style>
