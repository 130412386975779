<template>
  <div class="chart-container">
    <div class="section-h3">
      {{ $t('Chart.Chart_City') }}
      <div class="statistic-popover-container">
        <el-popover
          placement="bottom"
          width="168"
          trigger="click"
        >
          <div class="white-space">{{ description }}</div>
          <el-button slot="reference">
            <i class="el-icon-info" :aria-label="$t(`Aria.ReadInformation`)" />
          </el-button>
        </el-popover>
      </div>
    </div>
    <v-chart
      v-if="chartData.length > 0"
      class="chart"
      :option="option"
      autoresize
    />
    <el-empty
      v-else
      slot="empty"
      class="chart"
      :description="$t('general.NoData')"
    />
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex'
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'ChartCity', // for landing
  components: {
    'v-chart': VChart
  },
  props: {
    resChartData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartData: [],
      description: this.$t(`Chart.Chart_City_desc`)
    }
  },
  computed: {
    ...mapGetters(['language']),
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            name: '來源縣市',
            type: 'pie',
            cursor: 'default',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true, //  延伸線
            data: this.chartData,
            // data: [
            //   { value: 4831, name: '彰化縣' },
            //   { value: 2598, name: '高雄市' },
            //   { value: 1563, name: '嘉義市' },
            //   { value: 967, name: '桃園市' },
            //   { value: 892, name: '新北市' },
            //   { value: 784, name: '雲林縣' },
            //   { value: 763, name: '嘉義縣' },
            //   { value: 560, name: '臺北市' },
            //   { value: 3744, name: '未填' },
            //   { value: 1323, name: '其他' }
            // ],
            label: {
              position: 'outer',
              alignTo: 'edge',
              edgeDistance: '10',
              textStyle: {
                fontSize: 14
              }
              
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    language() { this.fetchData() }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.chartData = this.resChartData.cityNum.map((item) => ({
        name: this.$t(`City.${item.key}`),
        value: item.value
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.section-h3{
  text-align: center;
  line-height: 1.5;
  .statistic-popover-container{
    display: none;
      .el-button{
        background-color: transparent;
        border: transparent;
        padding: 5px;
      }
    i{
      color: $sport_yellow;
    }
  }
}
.chart-container:hover{
  .statistic-popover-container{
    display: inline-block;
  }
}
.chart {
  height: 100%;
  width: 100%;
  min-height: 200px; // landing page 500
  ::v-deep canvas{
    cursor: default; // important 無效
  }
  // @media (max-width: 576px){
  //   min-height: 300px;
  // }
}
</style>
