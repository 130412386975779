<template>
  <section id="usecase" class="landing-usecase-container" tabindex="0">
    <div class="usecase-block inner-container">
      <div class="section-top">
        <div class="section-h2 section-title">{{ $t('route.Achievements') }}</div>
        <RouterButton 
          :button-content="$t(`general.SeeMore`)"
          :router-name="isLogin? 'AchievementsLogin':'Achievements' "
          class="router-button router-button-desk"
        />
      </div>
      <el-row 
        :gutter="10"
      >
        <el-col
          v-for="item in landingUseCase"
          :key="item.displayName"
          :md="8"
          :sm="12"
          class="usecase-col"
        >
          <router-link
            :to="{ name: item.routerName }"
            :aria-label="`前往查看 [${item.displayName}] 案例詳細內容`"
          >
            <el-card 
              :body-style="{ padding: '0px' }"
              class="card-body"
            >
              <div>
                <div class="usecase-container">
                  <img :src="item.src" class="image" aria-hidden="true" alt="">
                  <div class="section-h3">{{ item.displayName }}</div>
                </div>
                <div class="description">{{ item.description }}</div>
                <div class="sr-only">{{ item.description }}</div>
              </div>
            </el-card>
          </router-link>
        </el-col>
      </el-row>
      <router-link 
        :to="{ name: 'Achievements' }" 
        class="router-button router-button-phone"
      >
        {{ $t(`general.SeeMore`) }} <i class="el-icon-right" />
      </router-link>
    </div>
  </section>
</template>

<script>
import { UseCase } from '@/Mixins'
import RouterButton from '@/components/Button/RouterButton.vue'

export default {
  name: 'LandingUseCase',
  components: {
    RouterButton
  },
  mixins: [UseCase]
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.landing-usecase-container{
    .section-top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 576px){
        display: block;
      }
    }
    .section-title{
      color: $text_title;
      padding: 1.25rem 0;
    }
    .router-button{
      border: transparent;
      font-size: 1rem;
      line-height: 1.5;
    }
    .router-button-desk{
      @media (max-width: 576px){
        display: none;
      }
    }
    .router-button-phone{
      display: none;
      @media (max-width: 576px){
        display: block;
        margin: 0 auto;
        line-height: 3;
        text-align: center;
      }
    }
    @media (max-width: 768px){
      .section-title{
        text-align: center;
      }
    }
    .inner-container{
      max-width: 1200px;
      margin: 0 auto;
      padding: 1.25rem 0;
    }
    @media (max-width: 1200px){
      .inner-container{
        max-width: 95%;
      }
    }
    .usecase-block{
      .el-row{
        flex-wrap: wrap;
        .card-body{
          height: 220px;
          line-height: 1.5;
          padding: 30px;
          transition-duration: 1s;

          .image {
            width: 70px;
            display: block;
          }     
        }
      }
      .usecase-col{
        margin-bottom: 1rem;
      }
      .description{
        display: none;
      }
      .card-body:hover{
        transition: 2s;
        background-color: $background_dark;
        .usecase-container{
          display: none;
        }
        .description{
          display: block;
          color: #FFFFFF;
        }
      }
    }
    .description{
      padding: 10px;
    }
  }
</style>
