<template>
  <div class="chart-container">
    <div class="section-h3">
      {{ $t('Chart.Chart_Sport') }}
      <div class="statistic-popover-container">
        <el-popover
          placement="bottom"
          width="200"
          trigger="click"
        >
          <div class="white-space">{{ description }}</div>
          <el-button slot="reference">
            <i class="el-icon-info" :aria-label="$t(`Aria.ReadInformation`)"  />
          </el-button>
        </el-popover>
      </div>
    </div>
    <v-chart
      class="chart"
      :option="option"
      autoresize
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  name: 'ChartSport',
  components: {
    'v-chart': VChart
  },
  props: {
    resChartData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartData: [],
      description: this.$t(`Chart.Chart_Sport_desc`)
    }
  },
  computed: {
    ...mapGetters(['language']),
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: [
          '#c6bdc2',
          '#066471',
          '#00c2cb',
          '#bbddeb',
          '#ffc738', 
          '#51859e',
          '#77797b', 
          '#1e5146',
          '#007949',
          '#3bbc61',
          '#10a99d',
          '#6ca14c',
          '#9c94fa'
        ],
        series: [
          {
            name: '主要運動類型',
            type: 'pie',
            cursor: 'default',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            data: this.chartData,
            // data: [
            //   { value: 148403, name: '騎車' },
            //   { value: 121830, name: '跑步' },
            //   { value: 75163, name: '健行' },
            //   { value: 10575, name: '游泳' },
            //   { value: 2065, name: '棒球' },
            //   { value: 1048, name: '步行' }
            // ],
            label: {
              position: 'outer',
              alignTo: 'edge',
              edgeDistance: '10',
              textStyle: {
                fontSize: 14
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    language() { this.fetchData() }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.chartData = Object.entries(this.resChartData.sportMainTypeNum
      ).map((
        [name, value]
      ) => ({
        name: this.$t(`mainType.${name}`),
        value
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.section-h3{
  text-align: center;
  line-height: 1.5;
  .statistic-popover-container{
    display: none;
      .el-button{
        background-color: transparent;
        border: transparent;
        padding: 5px;
      }
    i{
      color: $sport_yellow;
    }
  }
}
.chart-container:hover{
  .statistic-popover-container{
    display: inline-block;
  }
}
.chart {
  height: 100%;
  width: 100%;
  min-height: 200px;
  ::v-deep canvas{
    cursor: default;
  }
}
</style>
