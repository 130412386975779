<template>
  <el-main class="landing-container">
    <Special v-if="language === 'zh-TW'" />
    <Banner />
    <StatisticNum
      v-if="renderComponent"
      :statistic-num="statisticNum"
    />
    <DataChart
      v-if="renderComponent"
      :city-num="cityNum"
      :sport-main-type-num="sportMainTypeNum"
      :gender-num="genderNum"
      :all-place-type-num="allPlaceTypeNum"
      :last-update-date="lastUpdateDate"
    />
    <Introduce />
    <UseCase v-if="language === 'zh-TW'" />
    <Dataset />
    <News v-if="language === 'zh-TW'" />
    <JoinUs v-if="!isLogin" />
  </el-main>
</template>

<script>
import { mapGetters } from 'vuex'
import { StatisticData } from '@/Mixins'

import Banner from './components/Banner.vue'
import StatisticNum from './components/StatisticNum.vue'
import DataChart from './components/DataChart.vue'
import Dataset from './components/Dataset.vue'
import UseCase from './components/Usecase.vue'
import Introduce from './components/Introduce.vue'
import News from './components/News.vue'
import Special from './components/Special.vue'
import JoinUs from './components/Joinus.vue'

export default {
  name: 'LandingPage',
  components: {
    Banner,
    StatisticNum,
    DataChart,
    Dataset,
    UseCase,
    Introduce,
    News,
    Special,
    JoinUs
  },
  mixins: [StatisticData],
  data () {
    return {
      renderComponent: false,
      statisticNum: {},
      cityNum: [],
      sportMainTypeNum: {},
      genderNum: {},
      allPlaceTypeNum: {},
      lastUpdateDate: ''
    }
  },
  computed: {
    ...mapGetters(['language', 'token']),
    isLogin () {
      return !!this.token
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      this.$store.dispatch('info/getDatatype')
      this.$watch('statistic', this.renderChildComponent)
    },
    renderChildComponent(newVal) {
      if (newVal) {
        this.renderComponent = true
        this.statisticNum = newVal.statistic

        const sportMainType = newVal.main_type.find(item => item.Sport)
        this.sportMainTypeNum = sportMainType.Sport
        this.cityNum = newVal.city
        this.genderNum = newVal.gender
        this.allPlaceTypeNum = newVal.place_type.All
        this.lastUpdateDate = newVal.last_update_date
      }
    },
    login () {
      this.hideLogin = false
    },
    emitCloseLogin() {
      this.hideLogin = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.landing-container {
  color: $text_dark;
  font-size: 1rem;
}
</style>
