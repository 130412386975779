<template>
  <router-link 
    :to="{ name: routerName }" 
    :class="['router-button', buttonStyle]"
  >
    {{ translatedButtonContent }}
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'RouterButton',
  props: {
    buttonContent: {
      type: String,
      default: ''
    },
    routerName: {
      type: String,
      default: ''
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      translatedButtonContent: this.buttonContent
    }
  },
  computed: {
    ...mapGetters(['language']),
    buttonStyle() {
      return this.darkMode ? 'button-dark' : 'button-light'
    }
  },
  watch: {
    language: {
      immediate: true,
      handler() { 
        this.translatedButtonContent = this.buttonContent
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.router-button{
  text-align: center;
  display: block;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 0.875rem;
  max-width: 7.5rem;
  border:1px solid;
}
</style>
